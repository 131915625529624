module.exports = [{
      plugin: require('/home/gonzalo/src/gatsby/behindkamera/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Raleway","Allerta"]}},
    },{
      plugin: require('/home/gonzalo/src/gatsby/behindkamera/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/gonzalo/src/gatsby/behindkamera/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
